import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-date-fns";
import DeviceLayout from "./DeviceLayout";
Chart.register(...registerables);

const DeviceHistory = () => {


  const displayOrder = [
    "SOC",
    "PackTotalVoltage",
    "Ahtotal",
    "CurrentAct",
    "PowerAct",
    "CellMaxVoltage",
    "CellMinVoltage",
    "CellMaxTemp",
    "CellMinTemp",
    "ChargeCurrent",
    "DischargeCurrent",
    "ResetCnt",
    "EspResetCnt",
    "fw",
    "sfw",
  ];

  // Tablica z kluczami, które chcesz ukryć
  const hiddenFields = [
    "received_at", // Przykład: ukrywamy DischargeCurrent
    // Dodaj inne klucze, które chcesz ukryć
  ];

  // Obiekt z nowymi nazwami
  const displayNames = {
    SOC: "State of Charge (%)",
    PackTotalVoltage: "Voltage (V)",
    PowerAct: "Power (W)",
    Ahtotal: "Amphours (Ah)",
    CellMaxVoltage: "Max Cell Voltage (V)",
    CellMinVoltage: "Min Cell Voltage (V)",
    CurrentAct: "Current (A)",
    ChargeCurrent: "Charging Current (A)",
    DischargeCurrent: "Discharging Current (A)",
    CellMaxTemp: "Max Cell Temperature (°C)",
    CellMinTemp: "Min Cell Temperature (°C)",


    ResetCnt: "RSTM",
    EspResetCnt: "RESP",
    fw: "fw",
    sfw: "sfw",
  };


  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [timeRange, setTimeRange] = useState("1d");
  const [selectedData, setSelectedData] = useState([]);
  const [deviceData, setDeviceData] = useState({});
  const [customStartTime, setCustomStartTime] = useState("");
  const [customEndTime, setCustomEndTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const startTime = new Date(now);
    startTime.setDate(startTime.getDate() - 1);
    setCustomStartTime(utcToLocal(startTime));
    setCustomEndTime(utcToLocal(now));
    fetchDeviceData();
    const storedSelectedData = localStorage.getItem("selectedData");
    if (storedSelectedData) {
      setSelectedData(JSON.parse(storedSelectedData));
    }
    
  }, []);

  const utcToLocal = (date) => {
    const date2 = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
    return date2;
  };

  const localToUTC = (localDateString) => {
    return new Date(localDateString).toISOString().slice(0, 16);
  };

  const fetchDeviceData = async () => {
    const id = getDeviceIdFromUrl();
    try {
      const response = await fetch(`/api/device/data?device_id=${id}`);
      const jsonData = await response.json();
      updateTable(jsonData);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };

  const updateTable = (jsonData) => {
    if (Array.isArray(jsonData) && jsonData.length > 0) {
      setDeviceData(jsonData[0]); // Ustawienie deviceData na pierwszy element z tablicy
    } else {
      setDeviceData({}); // W przypadku braku danych, ustawienie pustego obiektu
    }
  };

  const updateChart = () => {
    const deviceId = getDeviceIdFromUrl();
    if (customStartTime === "" || customEndTime === "") return;

    if (selectedData.length === 0) {
      renderChart([]); // Render an empty chart or no data message
      return;
    }

    console.log(customStartTime);
    console.log(localToUTC(customStartTime));
    let url = `/api/device/data?device_id=${deviceId}&fields=${selectedData.join(
      ","
    )}&start_time=${localToUTC(customStartTime)}&end_time=${localToUTC(
      customEndTime
    )}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        renderChart(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const renderChart = (data) => {
    const ctx = chartRef.current.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy();
    }
  
    const hasData = data && data.length > 0;
  
    const chartData = {
      labels: hasData ? data.map((d) => new Date(d.received_at)) : [],
      datasets: [],
    };
  
    let yAxes = []; // Declare yAxes outside to ensure it's available
  
    // If no data is selected, show a message or empty dataset
    if (selectedData.length === 0) {
      chartData.datasets.push({
        label: "No Data Selected",
        data: [0, 0, 0, 0], // Dummy data for showing an empty graph
        borderColor: "gray",
        fill: false,
        pointRadius: 0,
      });
  
      // Set the fallback yAxes when no data is selected
      yAxes = [
        {
          id: "y-axis-0",
          type: "linear",
          position: "left",
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "No Data",
          },
        },
      ];
    } else {
      // Initialize arrays to track Y-axis groups
      const yAxisGroups = [];
  
      // Function to find the group for a dataset based on amplitude comparison
      const findYAxisGroup = (amplitude) => {
        for (let i = 0; i < yAxisGroups.length; i++) {
          const groupAmplitude = yAxisGroups[i].amplitude;
          if (
            amplitude.max <= groupAmplitude.max * 2 &&
            amplitude.min >= groupAmplitude.min / 2
          ) {
            return i; // Return the group index where the dataset fits
          }
        }
        return -1; // No matching group found
      };
  
      // Iterate through selected data fields
      selectedData.forEach((field, index) => {
        const dataForField = data.map((d) => ({
          x: new Date(d.received_at),
          y: d[field],
        }));
  
        // Calculate the amplitude (max - min) for the current dataset
        const values = data.map((d) => d[field]);
        const amplitude = {
          min: Math.min(...values),
          max: Math.max(...values),
        };
  
        // Find or create the appropriate Y-axis group based on amplitude
        let groupIndex = findYAxisGroup(amplitude);
        if (groupIndex === -1) {
          // Create a new Y-axis group if no existing group fits
          groupIndex = yAxisGroups.length;
          yAxisGroups.push({
            amplitude,
            fields: [field],
          });
        } else {
          // Add to an existing Y-axis group
          yAxisGroups[groupIndex].fields.push(field);
        }
  
        chartData.datasets.push({
          label: field,
          data: dataForField,
          borderColor: getColor(index),
          fill: true,
          backgroundColor: getColor(index, "20%"),
          pointRadius: 0,
          borderWidth: 2,
          yAxisID: `y-axis-${groupIndex}`, // Assign dataset to its Y-axis group
          spanGaps: false, // Prevent lines across time gaps
        });
      });
  
      // Create Y-axes based on the grouped datasets
      yAxes = yAxisGroups.map((group, index) => ({
        id: `y-axis-${index}`,
        type: "linear",
        position: index % 2 === 0 ? "left" : "right",
        beginAtZero: true,
        grid: {
          drawOnChartArea: index % 2 === 0, // Draw grid only on left-side Y-axes
        },
        title: {
          display: true,
          text: group.fields.join(", "), // Show fields grouped together on this axis
        },
      }));
    }
  
    // Create a new chart instance with the combined logic
    const newChartInstance = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: {
        animation: false,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: timeRange === "1h" || timeRange === "1d" ? "hour" : "day",
              tooltipFormat: "yyyy-MM-dd HH:mm", // Tooltip in 24-hour format
              displayFormats: {
                hour: "HH:mm",
                day: "yyyy-MM-dd",
              },
            },
          },
          y: yAxes, // Use yAxes which is now defined
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
    });
  
    setChartInstance(newChartInstance);
  };
  

  const getColor = (index, opacity = "50%") => {
    //const hue = (index * 137.508) % 360;
    const hue = (index * 20.508) % 360;
    return `hsl(${hue}, 70%, 50%, ${opacity})`;
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
    if (e.target.value !== "custom") {
      const now = new Date();
      let startTime;
      switch (e.target.value) {
        case "1h":
          startTime = new Date(now);
          startTime.setHours(now.getHours() - 1);
          break;
        case "1d":
          startTime = new Date(now);
          startTime.setDate(now.getDate() - 1);
          break;
        case "1w":
          startTime = new Date(now);
          startTime.setDate(now.getDate() - 7);
          break;
        case "1m":
          startTime = new Date(now);
          startTime.setMonth(now.getMonth() - 1);
          break;
        default:
          break;
      }
      setCustomStartTime(utcToLocal(startTime));
      setCustomEndTime(utcToLocal(now));
    } else {
      // When changing to custom, ensure the time range is set correctly
      setCustomStartTime(customStartTime);
      setCustomEndTime(customEndTime);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedData((prevSelectedData) => {
      const newSelectedData = checked
        ? [...prevSelectedData, value]
        : prevSelectedData.filter((item) => item !== value);

      // Save the selected data to localStorage
      localStorage.setItem("selectedData", JSON.stringify(newSelectedData));
      return newSelectedData;
    });
  };

  useEffect(() => {
    // Update chart whenever selectedData changes
    updateChart();
  }, [selectedData, customStartTime, customEndTime]);

  return (
    <DeviceLayout>
    <div>
      <div id="chart-container">
        <canvas
          ref={chartRef}
          id="data-chart"
          className="chart-container"
        ></canvas>
      </div>

      <div className="select-container">
        <div>
          <h3>Time Range:</h3>
          <p></p>
          <select
            id="time-range"
            value={timeRange}
            onChange={handleTimeRangeChange}
          >
            <option value="1h">Last Hour</option>
            <option value="1d">Last Day</option>
            <option value="1w">Last Week</option>
            <option value="1m">Last Month</option>
            <option value="custom">Custom</option>
          </select>
        </div>
      </div>
      <p></p>

      <div className="custom-timerange">
        <input
          type="datetime-local"
          id="start-time"
          value={customStartTime}
          onChange={(e) => {
            setCustomStartTime(e.target.value);
            setTimeRange("custom"); // Set to custom when modifying
          }}
        />
        <input
          type="datetime-local"
          id="end-time"
          value={customEndTime}
          onChange={(e) => {
            setCustomEndTime(e.target.value);
            setTimeRange("custom"); // Set to custom when modifying
          }}
        />
      </div>

      <h3>Select Data to Display:</h3>
      <table id="data-table">
        <thead>
          <tr>
            <th>Data Field</th>
            <th>Select</th>
            <th>Current Value</th>
          </tr>
        </thead>
        <tbody>
            {displayOrder.map((key) => (
              !hiddenFields.includes(key) && deviceData.hasOwnProperty(key) && (
                <tr key={key}>
                  <td>{displayNames[key] || key}</td> {/* Zmieniamy nazwę na wyświetlaną */}
                  <td>
                    <input
                      type="checkbox"
                      value={key}
                      checked={selectedData.includes(key)}
                      onChange={handleCheckboxChange}
                    />
                  </td>
                  <td>
                  {typeof deviceData[key] === "object"
            ? JSON.stringify(deviceData[key])
            : (deviceData[key] !== null ? (deviceData[key] * 1).toString() : "-")}
                  </td>
                </tr>
              )
            ))}
          </tbody>
      </table>
    </div>
    </DeviceLayout>
  );
};

export default DeviceHistory;
