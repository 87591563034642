import React, { useEffect, useState } from "react";
//import './App.css';
import DeviceLayout from "./DeviceLayout";

const DeviceDetail = () => {
  const [jsonData, setJsonData] = useState(null);
  const fetchData = async () => {
    const id = getDeviceIdFromUrl(); // Get device ID from URLx 
    if (id) {
      try {
        const response = await fetch(`/api/device/${id}`);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error("Error fetching device data:", error);
      }
    } else {
      console.error("Device ID not found in the URL.");
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, []);

  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };


  const hideAllEmptyColumns = () => {
    const table = document.getElementById("pack-data");
    const rows = table?.querySelectorAll("tr");
    if (!rows || rows.length === 0) return;
    const columnCount = rows[0].cells.length;

    for (let colIndex = 1; colIndex <= columnCount; colIndex++) {
      const cells = table.querySelectorAll(`td:nth-child(${colIndex})`);
      const values = Array.from(cells).map((cell) =>
        parseFloat(cell.textContent.trim())
      );
      const allLessThanZero = values.every((value) => value < 0.5);
      if (allLessThanZero) {
        cells.forEach((cell) => (cell.style.display = "none"));
        const headers = table.querySelectorAll(`th:nth-child(${colIndex})`);
        headers.forEach((header) => (header.style.display = "none"));
      }
    }
  };

  const replaceValuesLessThanThreshold = () => {
    const table = document.getElementById("pack-data");
    const cells = table?.querySelectorAll("td");
    cells?.forEach((cell) => {
      let value = parseFloat(cell.textContent.trim());
      if (value < -50) {
        cell.textContent = "---";
      }
    });
  };

  const updatePackTable = () => {
    if (!jsonData) return;

    const table = document.getElementById("pack-data");
    table.innerHTML = "";
    const headerRow = document.createElement("tr");
    const headerCells = [
      "BMS No",
      "Pack No",
      "Voltage",
      "Cell 1",
      "Cell 2",
      "Cell 3",
      "Cell 4",
      "Cell 5",
      "Cell 6",
      "Cell 7",
      "Cell 8",
      "Cell 9",
      "Cell 10",
      "Cell 11",
      "Cell 12",
      "Cell 13",
      "Cell 14",
      "Temp 1",
      "Temp 2",
      "Temp 3",
      "Temp 4",
      "Temp 5",
    ];

    headerCells.forEach((cellText) => {
      const cell = document.createElement("th");
      cell.textContent = cellText;
      headerRow.appendChild(cell);
    });
    table.appendChild(headerRow);

    const sortOrder = [
      'bat1-1', 'bat1-2', 'bat1-3', 'bat1-4', 'bat1-5', 'bat1-6', 'bat1-7', 'bat1-8',
      'bat1-9', 'bat1-10', 'bat1-11', 'bat1-12', 'bat1-13', 'bat1-14', 'bat1-15',
      'bat2-1', 'bat2-2', 'bat2-3', 'bat2-4', 'bat2-5', 'bat2-6', 'bat2-7', 'bat2-8',
      'bat2-9', 'bat2-10', 'bat2-11', 'bat2-12', 'bat2-13', 'bat2-14', 'bat2-15'
  ];

    // Natural sorting without a predefined list
    const sortedEntries = Object.entries(jsonData)
        .filter(([key]) => key.startsWith("bat")) // Filter only keys starting with 'bat'
        .sort((a, b) => {
            const [bmsA, packA] = a[0].match(/^bat(\d+)-(\d+)$/).slice(1);
            const [bmsB, packB] = b[0].match(/^bat(\d+)-(\d+)$/).slice(1);
            // Compare BMS number first, then Pack number
            return (Number(bmsA) - Number(bmsB)) || (Number(packA) - Number(packB));
        });
        let values =[];
    // Create table rows
    sortedEntries.forEach(([key, value]) => {
      if (key.startsWith("bat")) {
        const [bmsNo, packNo] = key.match(/^bat(\d+)-(\d+)$/).slice(1);
        const row = document.createElement("tr");
        const cells = [
          bmsNo,
          packNo,
          value.sv,
          value.v1,
          value.v2,
          value.v3,
          value.v4,
          value.v5,
          value.v6,
          value.v7,
          value.v8,
          value.v9,
          value.v10,
          value.v11,
          value.v12,
          value.v13,
          value.v14,
          value.pt1,
          value.pt2,
          value.pt3,
          value.pt4,
          value.pt5,
        ];

       

        cells.forEach((cell, index) => {
          const tableCell = document.createElement("td");
          if (index >= 3 && index <= 16) {
            const cellNumber = index - 2;
            const balancerState = (value.cb >> (cellNumber - 1)) & 1;
            if (balancerState === 1) {
            
              tableCell.style.backgroundColor = "#ffffcc";
            } else {
              tableCell.style.backgroundColor = "#f9f9f9";
            }
            if(cell>0)
              values.push(cell);
          }
          tableCell.innerHTML = cell;
          row.appendChild(tableCell);
        });
  
        // const maxValue = Math.max(...values);
        // const minValue = Math.min(...values);
        // console.log(minValue);
        // console.log(maxValue);
  
        // cells.forEach((cell, index) => {
        //   if (index >= 3 && index <= 16) {
        //     const tableCell = row.children[index];
        //     if (cell >= maxValue) {
        //       tableCell.style.backgroundColor = "#ccccff"; 
        //     } else if (cell <= minValue) {
        //       tableCell.style.backgroundColor = "#ffcccc";
        //     } else{
        //       tableCell.style.backgroundColor = "#f9f9f9";
        //     }
        //   }
        // });

        table.appendChild(row);
      }
    });
    hideAllEmptyColumns();
    replaceValuesLessThanThreshold();
  };

  const updateEvents = () => {
    if (!jsonData) return;
    const colorCodes = ["gray", "green", "yellow", "red", "darkred"];

    document.getElementById('BMSmaster').style.backgroundColor =
     colorCodes[jsonData.BMSmaster];
    document.getElementById("BMS1").style.backgroundColor =
      colorCodes[jsonData.BMS1];
    document.getElementById("BMS2").style.backgroundColor =
      colorCodes[jsonData.BMS2];
    document.getElementById("VCellMax").style.backgroundColor =
      colorCodes[jsonData.VCellMax];
    document.getElementById("VCellMin").style.backgroundColor =
      colorCodes[jsonData.VCellMin];
    document.getElementById("dV").style.backgroundColor =
      colorCodes[jsonData.dV];
    document.getElementById("TempMax").style.backgroundColor =
      colorCodes[jsonData.TempMax];
    document.getElementById("TempMin").style.backgroundColor =
      colorCodes[jsonData.TempMin];
    document.getElementById("CAN").style.backgroundColor =
      colorCodes[jsonData.CAN];
    document.getElementById("rel").style.backgroundColor =
      colorCodes[jsonData.rel];
    document.getElementById("Modbus").style.backgroundColor =
      colorCodes[jsonData.Modbus ? 4 : 1];
  };

  const updateTable = () => {
    if (!jsonData) return;

    document.getElementById("Title").textContent = jsonData.description;
    document.getElementById("CellCount").textContent = jsonData.CellCount;
    document.getElementById("CycleCount").textContent = jsonData.CycleCount;
    document.getElementById("PackTotalVoltage").textContent =
      jsonData.PackTotalVoltage * 1 + " V";
    document.getElementById("ChargeVoltage").textContent =
      jsonData.ChargeVoltage * 1 + " V";
    document.getElementById("DischargeVoltage").textContent =
      jsonData.DischargeVoltage * 1 + " V";
    document.getElementById("ChargeCurrent").textContent =
      jsonData.ChargeCurrent * 1 + " A";
    document.getElementById("DischargeCurrent").textContent =
      jsonData.DischargeCurrent * 1 + " A";
    document.getElementById("SOC").textContent = jsonData.SOC * 1 + " %";
    document.getElementById("CurrentAct").textContent =
      jsonData.CurrentAct + " A";
    document.getElementById("PowerAct").textContent =
      (jsonData.PowerAct / 1000) * 1 + " kW";
    document.getElementById("Ahtotal").textContent = jsonData.Ahtotal + " Ah";
    document.getElementById("CellMinVoltage").textContent =
      jsonData.CellMinVoltage + " V";
    document.getElementById("CellMaxVoltage").textContent =
      jsonData.CellMaxVoltage + " V";
      document.getElementById("CellAvgVoltage").textContent =
      (jsonData.PackTotalVoltage / jsonData.CellCount).toFixed(3) + " V";
    document.getElementById("dv").textContent =
      (jsonData.CellMaxVoltage - jsonData.CellMinVoltage).toFixed(3) + " V";
    document.getElementById("CellMinTemp").textContent =
      jsonData.CellMinTemp + " °C";
    document.getElementById("CellMaxTemp").textContent =
      jsonData.CellMaxTemp + " °C";
    //document.getElementById('sfw').textContent = jsonData.sfw;
    //document.getElementById('fw').textContent = jsonData.fw;
  };

  useEffect(() => {
    updateTable();
    updatePackTable();
    updateEvents();
  }, [jsonData]);

  return (
    <DeviceLayout>
    <div className="DeviceDetail">
      <div>
        <table>
          <thead>
            <tr>
              <th id="BMSmaster">BMSmaster</th>
              <th id="BMS1">BMS 1</th>
              <th id="BMS2">BMS 2</th>
              <th id="VCellMax">VCellMax</th>
              <th id="VCellMin">VCellMin</th>
              <th id="dV">dV</th>
              <th id="CAN">CAN</th>
              <th id="TempMax">TempMax</th>
              <th id="TempMin">TempMin</th>
              <th id="Modbus">Modbus</th>
              <th id="rel">Relay</th>
            </tr>
          </thead>
        </table>
        <br></br>
        <table>
          <tr>
            <th>SOC</th>
            <th>Pack Voltage</th>
            <th>Cell Min V</th>
            <th>Cell Max V</th>
            <th>Cell Avg V</th>            
            <th>dV</th>
            <th>Current</th>
            <th>Power</th>
            <th>Total Ah</th>
            <th>Pack Min Temp</th>
            <th>Pack Max Temp</th>
          </tr>
          <tr id="params">
            <td id="SOC"></td>
            <td id="PackTotalVoltage"></td>
            <td id="CellMinVoltage"></td>
            <td id="CellMaxVoltage"></td>
            <td id="CellAvgVoltage"></td>
            <td id="dv"></td>
            <td id="CurrentAct"></td>
            <td id="PowerAct"></td>
            <td id="Ahtotal"></td>
            <td id="CellMinTemp"></td>
            <td id="CellMaxTemp"></td>
          </tr>
        </table>
        <br></br>
        <table>
          <tr>
            <th>Charge Voltage</th>
            <th>Discharge Voltage</th>
            <th>Charge Current</th>
            <th>Discharge Current</th>
            <th>Cell Count</th>
            <th>Cycle Count</th>
          </tr>
          <tr>
            <td id="ChargeVoltage"></td>
            <td id="DischargeVoltage"></td>
            <td id="ChargeCurrent"></td>
            <td id="DischargeCurrent"></td>
            <td id="CellCount"></td>
            <td id="CycleCount"></td>
          </tr>
        </table>
      </div>
      <br></br>

      <div id="table2-container">
        <table id="pack-data"></table>
      </div>
    </div>
    </DeviceLayout>
  );
};

export default DeviceDetail;
