import React, { useEffect, useState } from "react";
import "../css/Layout.css"; // Optional: Include any additional styles
import { useLocation } from "react-router-dom";
import DeviceLayout from "./DeviceLayout";

const DeviceEvents = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // Track the current page
    const limit = 20; // Number of records to fetch per page
    const location = useLocation();

    // Function to extract device ID from the URL
    const getDeviceIdFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get("id");
    };

    // Fetch data whenever the component mounts or the page changes
    useEffect(() => {
        fetchData();
    }, [page]);

    const fetchData = async () => {
        const id = getDeviceIdFromUrl();
        if (id) {
            try {
                setError(null); // Clear any previous errors
                const response = await fetch(`/api/device/events?id=${id}&limit=${limit}&page=${page}`);
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                setData(data);
            } catch (error) {
                setError("Error fetching device data");
                console.error("Error fetching device data:", error);
            }
        } else {
            setError("Device ID not found in the URL.");
            console.error("Device ID not found in the URL.");
        }
    };

    // Handle pagination
    const handleNextPage = () => {
        setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    // Function to determine the cell color based on status value
    const getStatusColor = (value) => {
        if (value === 1) {
            return "green"; // Active or OK status
        } else if (value === 2) {
            return "yellow"; // Inactive or Error status
        } else if (value === 3) {
            return "red"; // Inactive or Error status
        } else if (value === 4) {
            return "red"; // Inactive or Error status
        } else {
            return "gray"; // Unknown or default status
        }
    };

    return (
      <DeviceLayout>
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                {data.length > 0 ? (
                    <table className="status-table">
                        <thead>
                            <tr>
                                <th>Received At</th>
                                <th>Elapsed time</th>
                                <th>BMS Master</th>
                                <th>BMS1</th>
                                <th>BMS2</th>
                                <th>CAN</th>
                                <th>Relay</th>
                                <th>VCell Min</th>
                                <th>VCell Max</th>
                                <th>dV</th>
                                <th>Temp Min</th>
                                <th>Temp Max</th>
                                <th>Modbus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((record, index) => (
                                <tr key={index}>
                                    <td>{new Date(record.received_at).toLocaleString()}</td>
                                    <td>{record.elapsed_time}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.BMSmaster) }}>{record.BMSmaster}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.BMS1) }}>{record.BMS1}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.BMS2) }}>{record.BMS2}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.CAN) }}>{record.CAN}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.rel) }}>{record.rel}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.VCellMin) }}>{record.VCellMin}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.VCellMax) }}>{record.VCellMax}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.dV) }}>{record.dV}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.TempMin) }}>{record.TempMin}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.TempMax) }}>{record.TempMax}</td>
                                    <td style={{ backgroundColor: getStatusColor(record.Modbus) }}>{record.Modbus}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available</p>
                )}
            </div>
            <div className="topdevice">
                <button onClick={handlePreviousPage} disabled={page === 1}>
                    Previous
                </button>
                <span> Page {page} </span>
                <button onClick={handleNextPage}>
                    Next
                </button>
            </div>
        </div>
      </DeviceLayout>
    );
};

export default DeviceEvents;
